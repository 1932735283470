var exports = {};
// We define these manually to ensure they're always copied
// even if they would move up the prototype chain
// https://nodejs.org/api/http.html#http_class_http_incomingmessage
const knownProperties = ["aborted", "complete", "headers", "httpVersion", "httpVersionMinor", "httpVersionMajor", "method", "rawHeaders", "rawTrailers", "setTimeout", "socket", "statusCode", "statusMessage", "trailers", "url"];

exports = (fromStream, toStream) => {
  if (toStream._readableState.autoDestroy) {
    throw new Error("The second stream must have the `autoDestroy` option set to `false`");
  }

  const fromProperties = new Set(Object.keys(fromStream).concat(knownProperties));
  const properties = {};

  for (const property of fromProperties) {
    // Don't overwrite existing properties.
    if (property in toStream) {
      continue;
    }

    properties[property] = {
      get() {
        const value = fromStream[property];
        const isFunction = typeof value === "function";
        return isFunction ? value.bind(fromStream) : value;
      },

      set(value) {
        fromStream[property] = value;
      },

      enumerable: true,
      configurable: false
    };
  }

  Object.defineProperties(toStream, properties);
  fromStream.once("aborted", () => {
    toStream.destroy();
    toStream.emit("aborted");
  });
  fromStream.once("close", () => {
    if (fromStream.complete) {
      if (toStream.readable) {
        toStream.once("end", () => {
          toStream.emit("close");
        });
      } else {
        toStream.emit("close");
      }
    } else {
      toStream.emit("close");
    }
  });
  return toStream;
};

export default exports;